<template>
    <h1>404 Page not Found</h1>
    <router-link :to="{ name: 'EventList' }">Back to Events</router-link>
</template>

<script>
export default {
    name: 'PageNotFound'
};
</script>

<style scoped></style>
